
(()=>{
	let W = 960;
	if($('#js-main').length <= 0 ) return;

	function onMatchMedia(query){
		if(query.matches){
			$('#menu-global')
				.removeClass('is-on')
				.removeAttr('style');
			$('#menu-toggle').removeClass('is-on');
		}
	}
	window.matchMedia( "(min-width:" + W + "px)").addListener( onMatchMedia );

	Fancybox.bind("[data-fancybox]", {
		groupAll: true,
		Toolbar: {
			items: {
				close: {
					tpl: '<button class="f-button" title="Close" data-fancybox-close=""><svg xmlns="http://www.w3.org/2000/svg" width="57.276" height="57.276" viewBox="0 0 57.276 57.276"><g id="ico_close_a_01" transform="translate(28.638 -27.931) rotate(45)"><path id="パス_2480" data-name="パス 2480" d="M0,0V80" transform="translate(40)" fill="none" stroke="#999" stroke-width="1"/><path id="パス_2481" data-name="パス 2481" d="M0,0V80" transform="translate(0 40.001) rotate(-90)" fill="none" stroke="#999" stroke-width="1"/></g></svg></button>',
				}, 
			},
			display: {
				left: [],
				middle: [],
				right: ["close"],
			}
		},
		Thumbs: false,
	});
	// Panzoom.defaults.zoom = false;
	
	
	let $grid = $('.grid').imagesLoaded(function() {
		$('.grid').masonry({
			itemSelector: '.grid-item',
			columnWidth: '.grid-item--width-s',
			fitWidth: true,
		});
		setTimeout(()=>{
			$('#js-main').stop().animate({opacity: 1}, 1500);
		}, 500)
	});

})();

function shuffleArray(a){
	for(let i=a.length-1; i>0; --i){
		let p = Math.floor(Math.random()*(i+1));
		if (p == i) continue;
		let tmp = a[i];
		a[i] = a[p];
		a[p] = tmp;
	}
}

$('#menu-toggle').on('click', function(){
	if( $(this).hasClass('is-on')){
		$('#menu-global').fadeOut(200);
	}else{
		$('#menu-global').fadeIn(200);
	}
	$(this).toggleClass('is-on')
})
